* {
  font-family: "Open Sans", sans-serif;
}

body {
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f9f7f3;
}

input::placeholder {
  color: #999;
}
